.account-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    gap: 10%;
    width: 100%;
    justify-content: center;
}

.account-form-wrapper {
    max-width: 350px;
    width: 100%; /* Full width for responsiveness */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3); /* Subtle box shadow for form */
    padding: 20px; /* Padding inside the form container */
    background-color: var(--off-white); /* Background color matching your theme */
    border-radius: 10px; /* Rounded corners */
}

.account-form {
    width: 100%; /* Ensure the form takes full width */
    display: flex; /* Flexbox for layout */
    flex-direction: column; /* Stack form fields */
}

.account-form input {
    width: 100%; /* Ensure inputs take full width */
    padding: 12px; /* Padding for input fields */
    margin-bottom: 20px; /* Increased margin for nice spacing between inputs */
    border-radius: 5px; /* Rounded corners */
    border: 1px solid #ccc; /* Subtle border */
    box-sizing: border-box; /* Ensure padding and border are included in width */
}

.account-form-wrapper input:focus {
    outline: none; /* Remove the default outline */
    border-color: var(--primary-theme); /* Highlight border on focus */
    box-shadow: 0 0 0 3px rgba(1, 150, 237, 0.3); /* Soft glow effect */
    transition: box-shadow 0.3s ease; /* Smooth transition on focus */
}

.account-form-wrapper button {
    width: 100%; /* Full width for button */
    padding: 12px; /* Larger padding for a prominent button */
    background-color: var(--primary-theme); /* Theme color */
    color: white; /* Text color */
    border: none; /* No border */
    border-radius: 5px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor on hover */
    font-size: 1rem; /* Clear font size */
    transition: background-color 0.3s ease; /* Smooth background transition */
}

.account-form-wrapper button:hover {
    background-color: var(--grey-white); /* Change on hover */
    color: black; /* Change text color */
    font-weight: 600; /* Bold text on hover */
}

.subscription-section {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3); /* Subtle box shadow for form */
    padding: 20px;
    border-radius: 10px;
    max-width: 250px;
    background-color: var(--off-white); /* Consistent background color */
}

.manage-subscription-link {
    display: inline-block;
    width: 100%; /* Full width for link button */
    text-align: center; /* Center the link text */
    padding: 10px 0px; /* Consistent padding with other buttons */
    background-color: var(--primary-theme); /* Theme color */
    color: white; /* Text color */
    text-decoration: none; /* No underline */
    border-radius: 5px; /* Rounded corners */
    margin-top: 15px; /* Space above the link */
    transition: background-color 0.3s ease; /* Smooth transition */
}

.manage-subscription-link:hover {
    background-color: var(--grey-white); /* Change on hover */
    color: black; /* Change text color */
    font-weight: 600; /* Bold text on hover */
}

/* Desktop styles */
@media (min-width: 768px) {
    .account-container {
        flex-direction: row;
    }

    .account-form-wrapper,
    .subscription-section {
        width: 48%; /* Use 48% for two-column layout */
    }
}

/* Mobile styles */
@media (max-width: 768px) {
    .account-container {
        padding: 10px; /* Compact padding */
        gap: 30px; /* Spacing between elements */
    }

    .account-form-wrapper {
        width: 90%; /* Use 90% of the width for responsiveness */
        padding: 15px; /* Adjust padding for a balanced appearance */
    }

    .account-form-wrapper h1 {
        font-size: 24px; /* Font size for headers */
        text-align: center; /* Center align text */
    }

    .account-form input {
        padding: 10px; /* Reduced padding for better fit */
        margin-bottom: 10px; /* Space between inputs */
        font-size: 0.9rem; /* Adjusted font size for better fit */
    }

    .account-form-wrapper button {
        padding: 10px; /* Reduced padding for better fit */
    }

    .subscription-section {
        width: 90%; /* Use 90% of the width for responsiveness */
        padding: 15px; /* Adjust padding for a balanced appearance */
        max-width: 350px;
    }

    .subscription-section h1 {
        font-size: 24px; /* Font size for headers */
        text-align: center; /* Center align text */
    }
}
