/* src/Header.css */
.header {
    background-color: var(--primary-theme);
    padding: 20px;
    text-align: center;
    border-bottom: 2px solid white;
    color: white;
}

.header-title {
    color: var(--white);
    margin: 0;
    font-size: 2.5rem;
}

.header-title a {
    color: white;
    text-decoration: none;
}

.header-nav {
    margin-top: 10px;
}

.header-nav a {
    color: var(--white);
    text-decoration: none;
    margin: 0 15px;
    font-size: 1.2rem;
}

.header-nav a:hover {
    text-decoration: underline;
}


.logout-button {
    border: 2px solid white;
    background-color: var(--primary-theme);
    padding: 4px 8px;
    border-radius: 4px;
    transition: 0.3s ease-in-out;
    cursor: pointer;
    color: var(--off-white);
    font-weight: 600;
    height: 28px;
    width: 66px;
    margin-left: 15px;
}

.logout-button:hover {
    background-color: var(--grey-white);
    border: none;
    color: black;
}

@media (max-width: 600px) {
    .header{
        padding: 10px;
    }
    .header-nav a {
        margin: 0 12px;
    }

    .logout-button {
        margin-left: 4px;
        margin-top: 6px;
    }

    .contact-link {
        display: none;
    }
    .homepage-subtitle {
        margin-bottom: 0;
    }
}