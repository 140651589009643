.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-form {
  background-color: var(--primary-theme);
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  width: 300px;
}

.login-title {
  color: white;
  text-align: center;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.login-button {
  width: 100%;
  padding: 10px;
  background-color: var(--primary-theme);
  color: white;
  border: 2px solid white;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  font-weight: 600;
  font-size: 16px;
  height: 43px;
}

.login-button:hover {
  background-color: var(--grey-white);
  border: none;
  color: black;
}

.login-button:disabled {
  background-color: grey;
}

.error-message {
  color: red;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .login-form {
    padding: 20px;
  }

  .login-container {
    width: 80%;
  }

  .login-button {
    font-size: 14px;
    height: auto;
    padding: 8px;
  }

  .login-title {
    margin-top: 0;
  }
}