.storyPage-container {
    width: 500px;
    margin: 2rem auto;
    padding: 2rem;
    border-radius: 12px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.4);
    background-color: var(--primary-theme);
    color: white;
}

.storyPage-title {
    text-align: center;
    margin-bottom: 1.5rem;
}

.storyPage-label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: bold;
    font-size: 22px;
}

.storyPage-input {
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 1rem;
    border: 2px solid #2980b9;
    border-radius: 8px;
    transition: border-color 0.3s;
}

.storyPage-input:focus {
    border-color: #1abc9c;
    /* Forest green on focus */
    outline: none;
}

.storyPage-button {
    background-color: var(--primary-theme);
    color: white;
    padding: 0.75rem 1.5rem;
    border: 2px solid white;
    border-radius: 8px;
    cursor: pointer;
    font-size: 18px;
    font-weight: 600;
    transition: background-color 0.3s;
    width: 100%;
}

.storyPage-button:hover {
    background-color: var(--off-white);
    color: black;
    font-weight: 600;
}

.storyPage-error {
    color: red;
    text-align: center;
    margin-top: 1rem;
}

.storyPage-output {
    margin-top: 2rem;
    padding: 1rem;
    background-color: #e9f5e9;
    border: 2px solid #2ecc71;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    color: black;
    font-size: 18px;
}

.storyPage-output h3 {
    color: #2c3e50;
    /* Slate blue */
}

.storyPage_word:hover {
    text-decoration: underline;
    cursor: pointer;
}

.translation_card {
    background-color: #f9f9f9;
    border: 4px solid white;
    padding: 2px 5px;
    box-shadow: 0 0 3px 4px var(--input-focus);
    z-index: 10;
    transform: translateY(-8px);
    /* Subtle initial translation */
    font-family: 'Arial', sans-serif;
    /* Clean, readable font */
    border-radius: 8px;
    position: fixed;
}

.translation_card>p {
    margin: 0;
}

.tooltip {
    position: relative;
    /* Keep tooltip positioned correctly */
    display: inline-block;
    /* So it behaves like inline text */
}

.active {
    cursor: pointer;
    /* Change cursor to pointer for active word */
}

.translation {
    background-color: #f9f9f9;
    border: 4px solid white;
    padding: 2px 5px;
    box-shadow: 0 0 3px 4px var(--input-focus);
    z-index: 10;
    font-family: 'Arial', sans-serif;
    border-radius: 8px;
    position: absolute;
    white-space: nowrap;
    transform: translate(-88%, 8px);
}


@media (max-width: 768px) {
    .storyPage-container {
        padding: 1.5rem;
        margin: 1rem auto;
        width: 82%;
    }

    .storyPage-title {
        font-size: 1.5rem;
        margin-top: 0;
    }

    .storyPage-button {
        padding: 0.5rem 1rem;
        /* Smaller button size on mobile */
        font-size: 14px;
    }

    .storyPage-input {
        padding: 0.4rem;
        /* Slightly smaller padding for inputs */
        font-size: 14px;
        /* Smaller input text */
    }

    .storyPage-output {
        padding: 0.8rem;
        margin-top: 1.5rem;
    }

    .storyPage-output h3 {
        font-size: 1.4rem;
        /* Smaller output title for mobile */
    }
    .storyPage-label {
        font-size: 16px;
    }
}