.error_message_wrapper {
    border: 1px solid var(--error-border);
    background-color: var(--error-background);
    margin: 10px 0;
    display: flex;
    justify-content: center;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    transition: opacity 0.5s ease-out; /* This enables the fade-out effect */
}

.fading-out {
    opacity: 0; /* Reduce opacity when fading out */
}

.error_message {
    margin: 8px;
}

.error_upgrade_link {
    text-decoration: underline;
    color: var(--link-blue);
    margin-left: 7px;
}

@media (max-width: 768px) {
    .error_message {
        font-size: 12px;
    }
}