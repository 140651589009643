/* General styles for the register form */
.register-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.register-form {
  background-color: var(--primary-theme);
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  width: 450px;
  /* Max width for the form */
  box-sizing: border-box;
}

.register-title {
  font-size: 2rem;
  color: white;
  text-align: center;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  font-size: 1rem;
  color: var(--white);
  margin-bottom: 5px;
}

.form-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid var(--forest-green);
  border-radius: 5px;
  font-size: 1rem;
  box-sizing: border-box;
}

.form-group input:focus {
  outline: none;
  box-shadow: 0 0 3px 4px  var(--input-focus);
}

.register-button {
  width: 100%;
  padding: 10px;
  color: white;
  background-color: var(--primary-theme);
  border: 1px solid white;
  border-radius: 5px;
  font-size: 1.2rem;
  cursor: pointer;
  font-weight: 600;
  transition: background-color 0.3s ease;
  height: 44px;
}

.register-button:hover {
  background-color: var(--grey-white);
  border: none;
  color: black;
}

.terms_message {
  color: white;
}

.terms_message > a {
  color: white;
}

@media (max-width: 768px) {
  .register-form {
    width: 80%;
    padding: 4px 20px;
  }

  .register-title {
    font-size: 1.5rem;
  }

  .register-button {
    font-size: 1rem;
    height: auto;
    padding: 8px;
  }

  .form-group input {
    padding: 8px;
    font-size: 0.9rem;
  }
}