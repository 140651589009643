html, body {
  height: 100%; /* Ensure the body takes the full height */
  margin: 0; /* Remove default margins */
  padding: 0; /* Remove default padding */
  box-sizing: border-box; /* Ensure consistent sizing */
  overflow-x: hidden; /* Prevents horizontal scrolling */
}

.App {
  display: flex; 
  flex-direction: column; 
  min-height: 100vh; 
}

header {
  flex-shrink: 0; 
}

main {
  flex: 1; 
  display: flex;
  justify-content: center;
  align-items: center;
}