/* src/HomePage.css */
:root {
    --primary-theme: #820933; 
    --off-white: #f9f9f9d5;
    --grey-white: #bdc3c7;
    --forest-green: #228B22;
    --error-border: rgb(205, 51, 51);
    --error-background: rgb(191 119 119 / 97%);
    --success-background: rgb(172, 229, 172);
    --success-border: rgb(75, 163, 75);
    --secondary-white: #ecf0f1;
    --input-focus: #E1C16E;
    --primary-theme-light: #a23f57; /* A lighter shade of your main color */
    --primary-theme-dark: #5f0d22;
    --link-blue: #0047AB;
}

.homepage-container {
    font-family: Arial, sans-serif;
    flex: 1;
    background-color: var(--off-white);
}

.homepage-header {
    background: linear-gradient(270deg, var(--primary-theme), var(--primary-theme-light), var(--primary-theme-dark), var(--primary-theme-light), var(--primary-theme));
    background-size: 600% 600%;
    animation: gradientAnimation 15s ease infinite; /* Increased duration for a smoother transition */
    padding: 30px 0;
    text-align: center;
    color: var(--off-white); /* Use off-white for contrast against darker colors */
}

@keyframes gradientAnimation {
    0% {
        background-position: 0% 100%;
    }
    25% {
        background-position: 100% 0%;
    }
    50% {
        background-position: 0% 100%;
    }
    75% {
        background-position: 100% 0%;
    }
    100% {
        background-position: 0% 100%;
    }
}


.homepage-header-content {
    padding: 20px;
}

.homepage-title {
    font-size: 3rem;
    margin: 0;
}

.homepage-subtitle {
    font-size: 1.5rem;
    margin-top: 10px;
}

.homepage-main {
    padding: 50px;
    text-align: center;
}

.homepage-features-title {
    font-size: 2rem;
    margin-bottom: 20px;
}

.homepage-features-list {
    display: flex;
    justify-content: center;
    gap: 40px;
    flex-wrap: wrap;
}

.feature-item {
    background-color: var(--secondary-white);
    padding: 20px;
    border-radius: 8px;
    width: 200px;
    text-align: center;
    box-shadow: 0 4px 8px #0000003a;
    transition: box-shadow 0.3s;
}

.feature-item:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.feature-icon {
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
}

.homepage-cta {
    margin: auto;
    width: 51%;
    margin-top: 50px;
    box-shadow: 0 4px 8px #0000001a;
    padding: 25px 0;
    background-color: var(--secondary-white);
    border-radius: 8px;
}

.homepage-cta-title {
    font-size: 2rem;
    margin-bottom: 10px;
}

.homepage-cta-text {
    font-size: 1.2rem;
    margin-bottom: 20px;
}

.homepage-cta-button {
    background-color: var(--secondary-white);
    color: black;
    padding: 10px 20px;
    border: 2px solid var(--primary-theme);
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.2rem;
    transition: background-color 0.3s, transform 0.3s;
}

.homepage-cta-button:hover {
    background-color: var(--primary-theme);
    color: white;
}

@media (max-width: 768px) {

    .homepage-header-content {
        padding: 10px;
    }
    .homepage-main {
        padding: 20px;
    }
    .homepage-title {
        font-size: 2rem;
    }

    .homepage-subtitle {
        font-size: 1.2rem; /* Responsive subtitle size */
    }

    .homepage-features-list {
        flex-direction: column;
        gap: 20px;
    }
    .homepage-header {
        padding: 15px 0;
    }
  
    .feature-item {
        width: 75%; /* Responsive feature item size */
    }
  
    .homepage-features-list {
        flex-direction: column; /* Stack features on smaller screens */
        align-items: center; /* Center items */
    }

    .homepage-cta {
        width: 75%;
        margin: 20px auto;
        padding: 20px;
    }
    .homepage-cta-title {
        margin: 0;
        font-size: 24px;
    }
    .homepage-cta-text {
        margin: 12px 0;
        font-size: 16px;
    }
    .homepage-cta-button {
        width: 100%; 
        padding: 5px;
        font-size: 1.2rem;
    }
    .homepage-features-title {
        margin-top: 5px;
    }
}