/* src/Footer.css */
.footer {
    flex-shrink: 0; /* Footer won't shrink and stays at the bottom */
    background-color: var(--primary-theme);
    color: var(--off-white);
    padding: 20px;
    text-align: center;
}

.footer-nav {
    margin-top: 10px;
    color: var(--off-white);
}

.footer > p {
    color: var(--off-white);
    margin: 0;
}

.footer-nav a {
    color: var(--off-white);
    text-decoration: none;
    margin: 0 8px;
}

.footer-nav a:hover {
    text-decoration: underline;
}
