/* Message.css */
.message_wrapper {
    background-color:var(--success-background);  
    color: black;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    opacity: 1;
    margin: 10px 0;
    border: 1px solid var(--success-border);
    transition: opacity 0.5s ease-out; /* Fade-out effect */
}

.fading-out {
    opacity: 0; 
}

.message_text {
    margin: 7px;
}