.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; /* Full height for the parent container */
}

.spinner {
    width: 40px;
    height: 40px;
    border: 6px solid white;
    border-top-color: var(--grey-white);
    border-radius: 50%;
    animation: spin 1s ease infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
