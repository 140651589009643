/* PrivacyPolicy.css */
.privacyPolicy-container {
    padding: 20px;
    max-width: 800px;
    margin: 50px auto;
    background-color: #f8f9fa;
    /* Off-white background */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}

.privacyPolicy-title {
    font-size: 2rem;
    color: var(--primary-theme);
    /* Slate blue */
    margin-bottom: 10px;
}

.privacyPolicy-intro {
    font-size: 1rem;
    color: #6B7280;
    /* Grey */
}

.privacyPolicy-content {
    font-size: 1rem;
    color: #2D3748;
    /* Darker shade for text */
    line-height: 1.5;
}

.privacyPolicy-subtitle {
    font-size: 1.5rem;
    color: var(--primary-theme);
    /* Forest green */
    margin-top: 20px;
    margin-bottom: 10px;
}

.privacyPolicy-list {
    padding-left: 20px;
}

.privacyPolicy-list li {
    margin-bottom: 5px;
}

.privacy_policy_email {
    color: var(--primary-theme);
}

/* Mobile Styles */
@media (max-width: 768px) {
    .privacyPolicy-container {
        padding: 15px;
        /* Reduce padding on smaller screens */
        margin: 20px;
        /* Reduce margin for smaller screens */
    }

    .privacyPolicy-title {
        font-size: 1.5rem;
        /* Smaller font size for title */
    }

    .privacyPolicy-intro,
    .privacyPolicy-content {
        font-size: 0.9rem;
        /* Slightly smaller font size for content */
    }

    .privacyPolicy-subtitle {
        font-size: 1.25rem;
        /* Smaller font size for subtitles */
    }

    .privacyPolicy-list {
        padding-left: 15px;
        /* Less padding for lists */
    }

    .privacyPolicy-list li {
        margin-bottom: 3px;
        /* Reduce margin between list items */
    }
}