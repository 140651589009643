.flashcards-container {
  margin: 40px auto;
  padding: 20px;
  background-color: #f0f5f9;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  width: 360px;
  max-width: 360px;
  background-color: var(--primary-theme);
}

.flashcards-header {
  background-color: #6A7B99;
  color: #fff;
  padding: 15px;
  text-align: center;
  font-size: 1.5em;
  border-radius: 8px 8px 0 0;
}

.language-selectors {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 15px;
  align-items: center;
  width: 100%;
}

.language-selectors h3 {
  margin: 0;
}

.language-selector {
  width: 100%;
  padding: 8px;
  border-radius: 8px;
  border: 2px solid #ddd;
  background-color: var(--off-white);
  font-size: 1em;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
  transition: border 0.3s ease, box-shadow 0.3s ease;
}

.selector-column {
  width: calc(100% - 20px);
  text-align: center;
}

.selector-column h3 {
  margin-bottom: 5px;
  color: var(--off-white);
}

.language-selector:hover {
  border-color: #6A7B99;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.flashcard-section h3 {
  margin: 0;
  margin-bottom: 15px;
}

.flashcard-header {
  font-size: 1.5em;
  margin-bottom: 20px;
  text-align: center;
  color: white;
}

.flashcard {
  background-color: var(--off-white);
  padding: 25px;
  border-radius: 12px;
  border: 1px solid #ddd;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.3s ease;
  width: 80%;
  margin: auto;
  font-size: 18px;
  height: 200px;
}

.flashcard button {
  padding: 5px 8px;
  background-color: var(--primary-theme);
  border: none;
  cursor: pointer;
  color: white;
  border-radius: 5px;
  font-weight: 600;
}

.flashcard button:hover {
  background-color: #5f0223fa;
}

.flashcard p {
  font-size: 1.1em;
  color: #333;
  font-weight: 600;
}

.flashcard p strong {
  font-weight: bold;
  color: var(--primary-theme);
}

.flashcard-navigation {
  display: flex;
  margin: auto;
  justify-content: space-between;
  margin-top: 15px;
  width: calc(100% - 20px);
}

.nav-button {
  background-color: var(--primary-theme);
  color: #fff;
  padding: 12px 30px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s ease;
  border: 2px solid var(--off-white);
  font-weight: 600;
}

.nav-button:hover {
  background-color: var(--off-white);
  color: black;
}

.nav-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
  color: white;
}


@media (max-width: 768px) {
  .flashcards-container {
    width: 82%;
  }
}

@media (max-width: 376px) {
  .flashcard {
    width: 77%;
  }
}