.tos-container {
    background-color: #f8f8f8; /* Off-white background */
    color: #3c3f43; /* Darker text for readability */
    padding: 20px;
    border-radius: 8px;
    max-width: 730px;
    margin: 50px auto;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); /* Adding box shadow */
}

.tos-header {
    padding: 10px;
    border-radius: 8px 8px 0 0;
}

.tos-title {
    color: var(--primary-theme); /* White text for title */
    text-align: center;
}

.tos-content {
    padding: 15px;
}

.tos-section-title {
    color: var(--primary-theme); /* Forest green for section titles */
    margin-top: 20px;
}

.tos-paragraph {
    line-height: 1.6;
    margin-bottom: 15px;
}

.tos-paragraph > a {
    color: var(--primary-theme);
    margin-left: 5px;
}

/* Mobile Styles */
@media (max-width: 768px) {
    .tos-container {
        padding: 15px; /* Reduce padding for mobile */
        margin: 20px; /* Reduce margin for mobile */
    }

    .tos-title {
        font-size: 1.8rem; /* Smaller font size for title */
    }

    .tos-section-title {
        font-size: 1.3rem; /* Smaller font size for section titles */
        margin-top: 15px; /* Less margin on top */
    }

    .tos-paragraph {
        font-size: 0.9rem; /* Smaller font size for paragraphs */
    }

    .tos-paragraph > a {
        font-size: 0.9rem; /* Adjust link size to match paragraph */
    }
}
