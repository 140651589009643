.contact-page-container {
    padding: 2rem;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: var(--primary-theme);
    width: 400px;
}

.contact-page-title {
    text-align: center;
    margin-bottom: 1.5rem;
    color: white;
}

.contact-form {
    display: flex;
    flex-direction: column;
    gap: 0rem;
}

.form-group {
    display: flex;
    flex-direction: column;
}

.form-input,
.form-textarea {
    padding: 0.75rem;
    border: 1px solid var(--primary-theme);
    border-radius: 4px;
    background-color: white;
    color: #333;
    font-size: 1rem;
}

.form-input:focus,
.form-textarea:focus {
    outline: none;
}

.form-textarea {
    height: 150px;
    resize: none;
}

.form-textarea:focus {
    box-shadow: 0 0 3px 4px  var(--input-focus);
}

.submit-button {
    padding: 0.75rem 1.5rem;
    background-color: var(--primary-theme);
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 18px;
    border: 1px solid white;
    cursor: pointer;
    transition: background-color 0.3s;
    font-weight: 600;
    height: 47px;
}

.submit-button:hover {
    background-color: var(--grey-white);
    color: black;
    border: none;
}

@media (max-width: 768px) {
    .contact-page-container {
        padding: 20px;
        width: 75%;
        max-width: 350px;
        margin: 0 auto;
    }

    .contact-page-title {
        font-size: 1.5rem;
        margin-bottom: 1rem;
        margin-top: 0;
    }

    .form-input,
    .form-textarea {
        padding: 0.65rem;
        font-size: 0.95rem;
    }

    .form-textarea {
        height: 120px;
    }

    .submit-button {
        padding: 8px 1rem;
        font-size: 1rem;
        height: auto; /* Let the height adjust based on content */
    }
}
