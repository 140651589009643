
.success-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #333; /* Dark gray text */
}

.congrats-message {
    font-size: 2rem;
    margin-bottom: 10px;
    font-weight: bold;
}

.welcome-message {
    font-size: 1.2rem;
    margin-bottom: 20px;
}

.countdown-message {
    font-size: 1rem;
    margin-bottom: 20px;
    color: #555; /* Slightly lighter gray */
}

.story-link {
    padding: 12px 24px;
    background-color: #4CAF50; /* Green background */
    color: white;
    text-decoration: none;
    border-radius: 25px;
    font-weight: 600;
    transition: background-color 0.3s;
}

.story-link:hover {
    background-color: #45a049; /* Darker green on hover */
}
